// src/components/TaxCalculator.js
import React, { useState } from 'react';
import './TaxCalculator.css';

const TaxCalculator = () => {
    const [monthlyIncome, setMonthlyIncome] = useState('');
    const [taxPoints, setTaxPoints] = useState('');
    const [vatAmount, setVatAmount] = useState(null);
    const [yearlyIncome, setYearlyIncome] = useState(null);
    const [incomeTax, setIncomeTax] = useState(null);
    const [socialSecurity, setSocialSecurity] = useState(null);
    const [totalTax, setTotalTax] = useState(null);
    const [monthlyNetProfit, setMonthlyNetProfit] = useState(null);
    const [yearlyNetProfit, setYearlyNetProfit] = useState(null);

    const calculateTaxes = () => {
        const monthlyIncomeNumber = parseFloat(monthlyIncome);
        const yearlyIncomeNumber = monthlyIncomeNumber * 12;

        // VAT Calculation
        const vat = monthlyIncomeNumber * 0.17;

        // Israeli Tax Calculation
        let tax = 0;
        if (yearlyIncomeNumber <= 84120) {
            tax = yearlyIncomeNumber * 0.10;
        } else if (yearlyIncomeNumber <= 120720) {
            tax = 8412 + (yearlyIncomeNumber - 84120) * 0.14;
        } else if (yearlyIncomeNumber <= 193800) {
            tax = 8412 + 5132 + (yearlyIncomeNumber - 120720) * 0.20;
        } else if (yearlyIncomeNumber <= 269280) {
            tax = 8412 + 5132 + 14616 + (yearlyIncomeNumber - 193800) * 0.31;
        } else if (yearlyIncomeNumber <= 560280) {
            tax = 8412 + 5132 + 14616 + 23448 + (yearlyIncomeNumber - 269280) * 0.35;
        } else {
            tax = 8412 + 5132 + 14616 + 23448 + 101949 + (yearlyIncomeNumber - 560280) * 0.47;
        }

        // Social Security Calculation
        const averageWage = 10644; // Example average wage in Israel
        const threshold = averageWage * 0.60;
        let socialSecurity = 0;
        if (monthlyIncomeNumber <= threshold) {
            socialSecurity = monthlyIncomeNumber * 0.0287;
        } else {
            socialSecurity = threshold * 0.0287 + (monthlyIncomeNumber - threshold) * 0.1283;
        }

        // Discount Points Calculation
        const taxPointValue = 2256; // Example value of a single tax point in NIS
        const discount = parseFloat(taxPoints) * taxPointValue;

        // Adjust tax with discount points
        tax = Math.max(0, tax - discount);

        // Total Tax Calculation
        const totalMonthlyTax = tax / 12 + socialSecurity + vat;
        const totalYearlyTax = totalMonthlyTax * 12;

        // Net Profit Calculation
        const monthlyNet = monthlyIncomeNumber - (tax / 12) - socialSecurity - vat;
        const yearlyNet = yearlyIncomeNumber - tax - (socialSecurity * 12) - (vat * 12);

        setVatAmount(vat.toFixed(2));
        setYearlyIncome(yearlyIncomeNumber.toFixed(2));
        setIncomeTax((tax / 12).toFixed(2));
        setSocialSecurity(socialSecurity.toFixed(2));
        setTotalTax(totalMonthlyTax.toFixed(2));
        setMonthlyNetProfit(monthlyNet.toFixed(2));
        setYearlyNetProfit(yearlyNet.toFixed(2));
    };

    return (
        <div className="calculator-container">
            <h1 className="title">Tax Calculator</h1>
            <div className="input-group">
                <label>
                    Monthly Income (including VAT):
                    <input
                        type="number"
                        value={monthlyIncome}
                        onChange={(e) => setMonthlyIncome(e.target.value)}
                    />
                </label>
            </div>
            <div className="input-group">
                <label>
                    Number of Tax Discount Points:
                    <input
                        type="number"
                        value={taxPoints}
                        onChange={(e) => setTaxPoints(e.target.value)}
                    />
                </label>
            </div>
            <button className="calculate-button" onClick={calculateTaxes}>Calculate Tax</button>
            {vatAmount !== null && incomeTax !== null && socialSecurity !== null && totalTax !== null && (
                <div className="results-container">
                    <h2>Monthly Results</h2>
                    <p>VAT Amount: ₪{vatAmount}</p>
                    <p>Income Tax: ₪{incomeTax}</p>
                    <p>Social Security: ₪{socialSecurity}</p>
                    <p>Total Tax: ₪{totalTax}</p>
                    <p>Monthly Net Profit: ₪{monthlyNetProfit}</p>
                    <h2>Yearly Results</h2>
                    <p>Yearly Income: ₪{yearlyIncome}</p>
                    <p>Yearly VAT Amount: ₪{(vatAmount * 12).toFixed(2)}</p>
                    <p>Yearly Income Tax: ₪{(incomeTax * 12).toFixed(2)}</p>
                    <p>Yearly Social Security: ₪{(socialSecurity * 12).toFixed(2)}</p>
                    <p>Total Yearly Tax: ₪{(totalTax * 12).toFixed(2)}</p>
                    <p>Yearly Net Profit: ₪{yearlyNetProfit}</p>
                </div>
            )}
        </div>
    );
};

export default TaxCalculator;